<template>
  <Layout class="main-container stock-con-1">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="5">
            <FormItem label="商品ID:" prop="ProductIDs">
              <Input placeholder="多个ID逗号隔开" v-model="params.ProductIDs" />
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem label="商品编号:" prop="Code">
              <Input placeholder="请输入商品编号" v-model="params.Code" />
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem prop="BrandID" label="商品品牌:">
              <Select clearable v-model="params.BrandID" placeholder="商品品牌">
                <Option
                  v-for="(item, index) in ProductBrandInfo"
                  :key="index"
                  :style="{ marginLeft: item.Layer * 20 + 'px' }"
                  :value="item.ID"
                >
                  {{ item.Name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem prop="ClassCode" label="商品分类:">
              <Select
                clearable
                v-model="params.ClassCode"
                placeholder="商品分类"
              >
                <Option
                  v-for="(item, index) in ProductClassInfo"
                  :key="index"
                  :value="item.Code"
                >
                  {{ item.Name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="5">
            <FormItem label="商品名称:" prop="Name">
              <Input placeholder="请输入商品名称" v-model="params.Name" />
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem prop="ProductType" label="商品类型:">
              <Select
                clearable
                v-model="params.ProductType"
                placeholder="商品类型"
              >
                <Option :value="10">普通商品</Option>
                <Option :value="20">组合商品</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem label="商品栏目:" prop="Topic">
              <Select clearable v-model="params.Topic" placeholder="商品栏目">
                <Option
                  v-for="(item, index) in ProductTopicEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem label="商品状态:" prop="Status">
              <Select clearable v-model="params.Status" placeholder="商品状态">
                <Option
                  v-for="(item, index) in ProductStatusEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="4">
            <FormItem label="是否售罄:" prop="IsSellOut">
              <Select
                clearable
                v-model="params.IsSellOut1"
                placeholder="是否售罄"
              >
                <Option
                  v-for="(item, index) in ProductIsSellOutEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left"></Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
      @on-selection-change="SelectionClick"
    >
      <template slot="Image" slot-scope="{ row }">
        <Tooltip placement="right">
          <Avatar
            :src="$setImagePrefix(row.DefaultImagePath, 'product')"
            style="min-width: 31px; height: 31px; margin-top: 3px"
          />
          <div slot="content" style="height: 230px">
            <img
              :src="$setImagePrefix(row.DefaultImagePath, 'product')"
              height="230"
              style="min-width: 230px; height: 230px; margin-top: 3px"
            />
          </div>
        </Tooltip>
      </template>
      <template slot="State" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.Status === 20 ? 'green' : row.Status === 30 ? 'red' : 'default'
          "
        >
          {{ row.StatusCN }}
        </Tag>
      </template>
      <template slot="BrandID" slot-scope="{ row }">
        <span>{{ brandText(row) }}</span>
      </template>
      <template slot="ClassCode" slot-scope="{ row }">
        <span>{{ classText(row) }}</span>
      </template>
      <template slot="Stock" class="stock-col" slot-scope="{ row }">
        <template v-if="row.ProductType === 20">
          <Button
            type="primary"
            :key="row.ID"
            size="small"
            @click="handleOpenJoint(row)"
            style="margin-right: 5px"
          >
            查看
          </Button>
        </template>
      </template>
      <template slot="switch" slot-scope="{ row }">
        <i-switch v-model="row.IsSellOut" @on-change="isSellOutChange(row)" />
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <!-- <add-product-modal @on-save="onUpdated"
                       ref="addProductModal" /> -->
    <!-- <product-info-drawer ref="productInfoDrawer" /> -->
    <!-- 编辑库存 -->
    <Modal
      class="edit-stock-model-1"
      class-name="i-modal"
      v-model="showEditStock"
      title="请输入增/减库存数"
      :mask-closable="false"
    >
      <Input
        type="number"
        class="edit-stock-input"
        v-model="inputStock"
        placeholder="请输入整数"
        ref="edit-stock-input-ref"
        size="large"
      />
      <p>正数表示：在总库存基础上增加该数量。</p>
      <p>负数表示：在总库存基础上减少该数量。</p>
      <template slot="footer">
        <div class="modal-foot-1">
          <Button size="large" type="primary" @click="editStockOk">
            确定
          </Button>
        </div>
      </template>
    </Modal>
  </Layout>
</template>
<script>
// import AddProductModal from './components/add-product-modal';
import api from "@/api";
import Layout from "@/components/layout/index.vue";
import { mapActions } from "vuex";
import bizEnum from "@/libs/bizEnum";
// import productInfoDrawer from './components/product-info-drawer';
export default {
  name: "ProductStock",
  data() {
    return {
      inputStock: null,
      showEditStock: false,
      NewClass: [],
      cacheUpdatedTime: "",
      tableLoading: false,
      tableHeight: 0,
      productRow: {},
      selection: [], // 当前选中行(删除参数)
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      params: {
        Topic: "",
        Code: "",
        Name: "",
        ProductIDs: "",
        ClassCode: "",
        BrandID: "",
        FunctionID: "",
        IsSellOut: "",
        Status: null,
        ProductType: null,
        IsSearchTest: 1,
        IsChild: 1,
        Page: 1,
        PageSize: 40,
        SortName: "Code",
        SortOrder: "Asc",
      },
      columns: [
        {
          title: "商品图片",
          key: "IconPath",
          minWidth: 75,
          align: "center",
          slot: "Image",
        },
        {
          title: "商品ID",
          key: "ID",
          minWidth: 111,
          align: "center",
          sortable: "custom",
        },
        {
          title: "商品编号",
          key: "Code",
          minWidth: 140,
          align: "left",
          sortable: "custom",
        },
        {
          title: "商品名称",
          key: "Name",
          minWidth: 140,
          align: "left",
          sortable: "custom",
        },
        // {
        //   title: "商品系列",
        //   key: "SeriesID",
        //   slot: "SeriesID",
        //   align: "left",
        //   minWidth: 100,
        // },
        {
          title: "商品品牌",
          key: "BrandID",
          slot: "BrandID",
          align: "left",
          minWidth: 100,
        },
        {
          title: "商品分类",
          key: "ClassCode",
          slot: "ClassCode",
          align: "left",
          minWidth: 100,
        },
        {
          title: "是否售罄",
          key: "IsSellOut",
          slot: "switch",
          minWidth: 100,
        },
        {
          title: "状态",
          key: "Status",
          slot: "State",
          align: "center",
          minWidth: 100,
          sortable: "custom",
        },
        {
          title: "剩余库存",
          key: "Stock",
          align: "center",
          minWidth: 100,
        },
        {
          title: "已售",
          key: "Sells",
          align: "center",
          minWidth: 100,
        },
        {
          title: "创建时间",
          key: "CreateDate",
          width: 135,
          align: "center",
          sortable: "custom",
        },
      ],
      nowEditStock: 0,
      nowEditId: 0,
    };
  },
  created() {
    this.loadList();
    this.getProductBrandInfo();
    this.getProductClassInfo();
    this.$getCacheUpdatedTime("AllProduct", this);
  },
  components: { Layout },
  methods: {
    ...mapActions(["getProductBrandInfo", "getProductClassInfo"]),
    EditDrawText(row) {
      this.nowEditStock = row.Stock;
      this.nowEditId = row.ID;
      this.inputStock = null;
      this.showEditStock = true;
      this.$nextTick(() => {
        this.$refs["edit-stock-input-ref"].focus();
      });
    },
    async editStockOk() {
      if (!this.inputStock) {
        return this.$Message.error("请先输入数量");
      }
      if (this.nowEditStock + parseInt(this.inputStock) < 0) {
        return this.$Message.error("修改后总库存不能小于0");
      }
      const _this = this;
      try {
        _this.tableLoading = true;
        const res = await api.AddProductStock({
          productID: this.nowEditId,
          count: parseInt(this.inputStock),
        });
        if (res.Status === 100) {
          _this.$Message.success("保存成功");
          let findObj = _this.resObj.DataList.find(
            (f) => f.ID === this.nowEditId
          );
          if (findObj) {
            findObj.TotalStock = res.Data.TotalStock;
            findObj.Sells = res.Data.Sells;
            findObj.Stock = res.Data.Stock;
          }
          _this.tableLoading = false;
        } else {
          _this.$Message.error(res.Msg);
          _this.tableLoading = false;
        }
        this.showEditStock = false;
      } catch (error) {
        _this.tableLoading = false;
        this.showEditStock = false;
      }
    },
    handleOpenJoint(row) {
      const _this = this;
      _this.row = row;
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadList();
    },
    seriesText(row) {
      let temp =
        this.ProductSeriesInfo &&
        this.ProductSeriesInfo.find((item) => {
          return String(item.ID) === String(row.SeriesID);
        });
      return temp ? temp.Name : "加载中";
    },
    brandText(row) {
      let temp =
        this.ProductBrandInfo &&
        this.ProductBrandInfo.find((item) => {
          return String(item.ID) === String(row.BrandID);
        });
      return temp ? temp.Name : "加载中";
    },
    classText(row) {
      let temp = [];
      if (this.ProductClassInfo) {
        const classIDArr = row.ClassCode.split(",");
        temp = this.ProductClassInfo.filter((item) =>
          classIDArr.includes(item.Code)
        );
      }
      return temp.map((item) => item.Name).join(",");
    },
    async loadList() {
      this.tableLoading = true;
      try {
        if (this.params.IsSellOut1 === 1) {
          this.params.IsSellOut = true;
        } else if (this.params.IsSellOut1 === 0) {
          this.params.IsSellOut = false;
        }
        this.params.CheckSub = true;
        const res = await api.GetProductList(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.resObj.DataList = res.Data.DataList.map((item) => {
            if (!item.TotalStock) {
              item.TotalStock = item.Stock + item.Sells;
            }
            return item;
          });
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleReset() {
      this.params.IsSellOut1 = "";
      this.$refs.SearchForm.resetFields();
      this.loadList();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadList();
    },
    onUpdated(row) {
      row.Topic = row.Topic.split(",");
      if (row.ID > 0) {
        const index = this.resObj.DataList.findIndex(
          (item) => item.ID === row.ID
        );
        if (index >= 0) {
          this.resObj.DataList.splice(index, 1, row);
        } else {
          this.resObj.DataList.unshift(row);
        }
      }
    },
    async Delete(row) {
      try {
        const res = await api.DeleteProduct({
          ids: row.ID,
        });
        if (res.Data) {
          row.Status = -99;
          row.StatusCN = "已删除";
          const index = this.resObj.DataList.findIndex(
            (item) => item.ID === row.ID
          );
          this.resObj.DataList.splice(index, 1, row);
          this.$Message.success("删除成功");
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    handleOpenUpdateState(type) {
      // 批量下架
      if (this.selection.length <= 0) {
        this.$Message.error("请先选择需要修改的行！");
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        loading: true,
        content: "<p>确定要修改选中行的状态?</p>",
        onOk: () => {
          this.UpdateState(type);
        },
        onCancel: () => {},
      });
    },
    async UpdateState(type) {
      const _this = this;
      try {
        if (type === "UP") {
          const res = await api.BatchUpdateProductStatus({
            upids: this.selection,
          });
          if (res.Status === 100) {
            this.$Message.success("操作成功");
            this.selection.forEach((item) => {
              let product = _this.resObj.DataList.find((it) => it.ID === item);
              let ix = _this.resObj.DataList.findIndex((it) => it.ID === item);
              product.Status = 1;
              product.StatusCN = "已上架";
              if (product) {
                this.resObj.DataList.splice(ix, 1, product);
              }
            });
            this.$Modal.remove();
          } else {
            this.$Message.error(res.Msg);
            this.$Modal.remove();
          }
        } else {
          const res = await api.BatchUpdateProductStatus({
            donids: this.selection,
          });
          if (res.Status === 100) {
            this.$Message.success("操作成功");
            this.selection.forEach((item) => {
              let product = _this.resObj.DataList.find((it) => it.ID === item);
              let ix = _this.resObj.DataList.findIndex((it) => it.ID === item);
              product.Status = 2;
              product.StatusCN = "已下架";
              if (product) {
                this.resObj.DataList.splice(ix, 1, product);
              }
            });
            this.$Modal.remove();
          } else {
            this.$Message.error(res.Msg);
            this.$Modal.remove();
          }
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    async handleOpenBatchDelete() {
      if (this.selection.length <= 0) {
        this.$Message.error("请先选择需要修改的行！");
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        content: "您确定要删除选中行吗？",
        loading: true,
        onOk: () => {
          this.BatchDelete();
        },
      });
    },
    async BatchDelete() {
      const _this = this;
      try {
        const res = await api.DeleteProduct({
          ids: this.selection,
        });
        if (res.Status === 100) {
          this.$Message.success("操作成功");
          this.selection.forEach((item) => {
            let product = _this.resObj.DataList.find((it) => it.ID === item);
            let ix = _this.resObj.DataList.findIndex((it) => it.ID === item);
            product.Status = -99;
            product.StatusCN = "已删除";
            if (product) {
              this.resObj.DataList.splice(ix, 1, product);
            }
          });
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    isSellOutChange(row) {
      const _this = this;
      _this.$Modal.confirm({
        title: "提示",
        content:
          "您确定要将【" +
          row.ID +
          "】改为" +
          (row.IsSellOut ? "售罄" : "非售罄") +
          "？",
        onOk: function () {
          _this.changeSellOut(row.ID, row.IsSellOut);
        },
        onCancel: () => {
          _this.loadList();
        },
      });
    },
    async changeSellOut(productID, isSellOut) {
      const _this = this;
      try {
        _this.tableLoading = true;
        const res = await api.ChangeSellOut({
          productID: productID,
          isSellOut: isSellOut,
        });
        if (res.Status === 100) {
          _this.$Message.success("保存成功");
          _this.tableLoading = false;
        } else {
          _this.$Message.error(res.Msg);
          _this.tableLoading = false;
        }
      } catch (error) {
        _this.$Notice.error({
          title: "错误",
          desc: error,
        });
        _this.tableLoading = false;
      }
    },
    // 选项发生改变方法
    SelectionClick(selection) {
      const _this = this;
      // if (selection.length > 0) _this.WaitDelete = true
      // else _this.WaitDelete = false
      _this.selection = [];
      selection.map((item) => {
        _this.selection.push(item.ID);
      });
    },
    keydownEvent(e) {
      console.log(this.$route.name, this.$options.name);
      if (this.$route.name === this.$options.name && e.keyCode === 13) {
        this.loadList();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$getTableHeight("main-container");
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = this.$getTableHeight("main-container");
      });
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.loadList();
        this.$getCacheUpdatedTime("AllProduct", this);
      }
    });
  },
  activated() {
    window.addEventListener("keydown", this.keydownEvent);
  },
  deactivated() {
    window.removeEventListener("keydown", this.keydownEvent);
  },
  computed: {
    ProductTopicEnum() {
      return bizEnum.ProductTopicEnum;
    },
    ProductStatusEnum() {
      return bizEnum.ProductStatusEnum;
    },
    ProductIsSellOutEnum() {
      return bizEnum.ProductIsSellOutEnum;
    },
    ProductBrandInfo() {
      return this.$store.state.product.brandList;
    },
    ProductClassInfo() {
      return this.$store.state.product.classList;
    },
  },
};
</script>

<style lang="less">
.stock-con-1 {
  .stock-span {
    display: inline-block;
    width: calc(100% - 40px);
  }
  .stock-col {
    text-align: right;
  }
}
.edit-stock-model-1 {
  .modal-foot-1 {
    text-align: center;
    .ivu-btn {
      width: 100px;
    }
  }
  p {
    color: #f36a19;
  }
  .edit-stock-input {
    width: 300px;
    margin-bottom: 10px;
  }
  .ivu-modal-body {
    padding: 30px;
    text-align: center;
  }
}
</style>
